import React from 'react'

import { OptionT, EmployeeParam } from '@types'

import { Select, Input } from '@components'
import { EMPLOYEE_STATUS_OPTIONS, SEARCH_EMPLOYEE_ROLE_OPTIONS } from '@lib/constants'

type Props = {
  onChange: (filter: EmployeeParam) => void
  totals: number
  search?: EmployeeParam
  teamOptions: OptionT[]
  page: string
}
export const SearchFilter = ({
  onChange,
  totals,
  search,
  teamOptions,
  page,
}: Props) => {
  return (
    <div className="flex flex-wrap sm:flex-col md:flex-row gap-2 my-4 md:items-center sm:items-baseline">
      <Input
        name="query"
        value={search?.search}
        placeholder={'Search'}
        onChange={(value) =>
          onChange({ ...search, search: value as string})
        }
      />
      <Select
        onChange={(value) => {
          onChange({ ...search, department_id: value?.value as number })
        }}
        options={teamOptions ?? []}
        value={search?.department_id}
      />
      {page === 'user' && (
        <Select
          onChange={(value) => {
            onChange({ ...search, status: value?.value as string })
          }}
          options={EMPLOYEE_STATUS_OPTIONS}
          value={search?.status || 'active' }
        />
      )}

      {page === 'user' && (
        <Select
        onChange={(value) =>
          onChange({ ...search, role: value?.value as string })
        }
        options={SEARCH_EMPLOYEE_ROLE_OPTIONS}
        value={search?.role}
      />
      )}

      <div className="font-bold text-sm sm:gap-4">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
